<template>
  <div class="productList">
    <div>
      <span class="mr_20">{{
        myShop.name
      }}</span>
    </div>
    <div class="derive">
      <el-button
        size="small"
        @click="createProduct()"
        v-if="(managerType != 0 || getAuth('productOperate')) && infoBusiness.manage_product == 1"
        >添加商家产品</el-button
      >
    </div>
    <div class="bill-table">
      <base-table
        :columns="productCol"
        :data="tableData"
        :pagination="searchForm"
        :total="total"
        @getData="initData"
        :loading="loading"
        :isPaginationShow="true"
        wrapperHeight="calc(100% - 45px)"
      >
        <el-table-column
          slot="operation"
          label="操作"
          align="center"
          fixed="right"
          v-if="(managerType != 0 || getAuth('productOperate')) && infoBusiness.manage_product == 1"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="editProduct(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="delProduct(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </base-table>
    </div>
    <productModal ref="productModal" />
  </div>
</template>

<script>
import columns from "./columns";
import productModal from "./components/product-modal.vue";
import { suggestBusinessList, infoBusiness } from "@/api/business.js";
import { getMyShop, getAuth } from "@/utils/index.js";
import { listProduct, statusProduct } from "@/api/product.js";
export default {
  name: "productList",
  components: { productModal },
  data() {
    return {
      managerType: localStorage.getItem("__managerType__"),
      getAuth: getAuth,
      myShop: getMyShop(),
      baseBusinessList: [],
      businessList: [],
      businessLoading: false,
      searchForm: {
        page: 1,
        per_page: 10,
      },
      ...columns,
      tableData: [],
      total: 0,
      loading: false,
      infoBusiness: {},
    };
  },
  async mounted() {
    this.initData();
    this.baseBusinessList = await this.getSelectBusiness();
  },

  methods: {
    async initData() {
      this.getRepaymentList();
    },
    async getRepaymentList() {
      this.searchForm.businessId = this.myShop.id;
      await this.getProductList(this.searchForm);
      await this.getInfoBusiness(this.searchForm.businessId);
    },
    async getProductList(param) {
      const { code, data } = await listProduct(param);
      if (code == 200) {
        this.tableData = data.data;
      }
    },
    async getInfoBusiness(businessId) {
      const param = {
        businessId: businessId,
      };
      const { code, data } = await infoBusiness(param);
      if (code == 200) {
        this.infoBusiness = data;
      }
    },
    createProduct() {
      if (this.searchForm.businessId) {
        this.$refs.productModal.isShow("add", this.searchForm.businessId);
      } else {
        this.$message({
          message: "请选择商家",
          type: "warning",
        });
      }
    },
    editProduct(row) {
      this.$refs.productModal.isShow("edit", this.searchForm.businessId, row);
    },
    businessRemoteMethod(query) {
      if (query) {
        this.businessLoading = true;
        setTimeout(async () => {
          this.businessLoading = false;
          this.businessList = await this.getSelectBusiness(query);
        }, 200);
      } else {
        this.businessList = this.baseBusinessList;
      }
    },
    async getSelectBusiness(keyword) {
      const { code, data } = await suggestBusinessList({
        keyword: keyword || "",
      });
      if (code == 200) {
        return data;
      }
      return [];
    },

    delProduct(row) {
      console.log("del---->", row);
      this.$confirm("是否删除当前产品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const parmes = {
            product_id: row.product_id,
            status: 2,
          };
          const { code } = await statusProduct(parmes);
          if (code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.initData();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.productList {
  .derive {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}
</style>